import BaseModel from "~/models/BaseModel";
import dayjs from "dayjs";

class NewsArticleModel extends BaseModel {
  showReleasedAt() {
    return dayjs(this.releasedAt).format("YYYY/MM/DD HH:mm");
  }
}

export default NewsArticleModel;
