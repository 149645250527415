import Repository from "./Repository";
import BaseModel from "~/models/BaseModel";
import { serialize } from "object-to-formdata";
import qs from "qs";
// getでネストしたクエリを投げる際に使用する
const paramsSerializer = apiParams => qs.stringify(apiParams);

export default class BaseRepository {
  async _get(url, params = {}) {
    const apiParams = new BaseModel(params).toApiParams();
    const results = await Repository.get(url, { params: apiParams, paramsSerializer });
    return results;
  }

  async _post(url, params = {}) {
    const apiParams = new BaseModel(params).toApiParams();
    const results = await Repository.post(url, apiParams);
    return results;
  }

  async _put(url, params = {}) {
    const apiParams = new BaseModel(params).toApiParams();
    const results = await Repository.put(url, apiParams);
    return results;
  }

  async _delete(url, params = {}) {
    const apiParams = new BaseModel(params).toApiParams();
    const results = await Repository.delete(url, { params: apiParams, paramsSerializer });
    return results;
  }

  // formデータ用に変換してpostする
  async _postWithFormData(url, params = {}) {
    const apiParams = new BaseModel(params).toApiParams();
    const formData = serialize(apiParams);
    const results = await Repository.post(url, formData);
    return results;
  }
}
