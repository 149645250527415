import BaseModel from "~/models/BaseModel";
import "dayjs/locale/ja";
import dayjs from "dayjs";

class TradeDiaryModel extends BaseModel {
  winCount() {
    return this.notCancelledTradeHistories().filter(history =>history.pips > 0).length;
  }

  lossCount() {
    return this.notCancelledTradeHistories().filter(history => history.pips < 0).length;
  }

  drawCount() {
    return this.notCancelledTradeHistories().filter(history => history.pips === 0).length;
  }

  winningPercentage() {
    const total = this.notCancelledTradeHistories().length;
    const percentage = (this.winCount() / total) * 100;
    return Math.round(percentage);
  }

  winRateData() {
    return this.winningPercentage() ? [this.winningPercentage(), 100 - this.winningPercentage()] : [0, 1000];
  }

  buyTradeCount() {
    return this.notCancelledTradeHistories().filter(history =>
      history.tradeType.toLowerCase() === "buy"
    ).length;
  }

  cancelledTradeCount() {
    return this.tradeHistories.filter(function(history) {
      return history?.comment?.includes("cancelled");
    }).length;
  }

  sellTradeCount() {
    return this.notCancelledTradeHistories().filter(history =>
      history.tradeType.toLowerCase() === "sell"
    ).length;
  }

  totalProfitAndLoss() {
    return this.notCancelledTradeHistories().reduce((a, x) => a+=x.totalProfit, 0);
  }

  totalPips() {
    return this.notCancelledTradeHistories().reduce((a, x) => a+=x.pips, 0);
  }

  totalProfit() {
    return this.notCancelledTradeHistories().reduce((a, x) => a+=x.profit, 0);
  }

  totalCommission() {
    return this.notCancelledTradeHistories().reduce((a, x) => a+=x.commission, 0);
  }

  graphLabels() {
    return this.openTimeAscSorting().map(item => dayjs(item.showOpenTime).format("YYYY/MM/DD HH:mm:ss"));
  }

  // グラフの表示がtotal_profitかpipsか
  graphTypeTotalProfit() {
    return this.graphData.unitType === "total_profit";
  }

  graphBarData() {
    if (this.graphTypeTotalProfit()) {
      return this.openTimeAscSorting().map(item => item.totalProfit);
    } else {
      return this.openTimeAscSorting().map(item => item.pips);
    }
  }

  graphLineData() {
    let sum = 0;
    if (this.graphTypeTotalProfit()) {
      return this.openTimeAscSorting().map(item => sum += item.totalProfit);
    } else {
      return this.openTimeAscSorting().map(item => sum += item.pips);
    }
  }

  openTimeAscSorting() {
    return this.notCancelledTradeHistories().sort((a, b) => ((a.showOpenTime < b.showOpenTime) ? -1 : 1));
  }

  // private

  notCancelledTradeHistories() {
    return this.tradeHistories.filter(function(history) {
      return !history?.comment?.includes("cancelled");
    });
  }
}

export default TradeDiaryModel;
