import BaseRepository from "./BaseRepository";
import BaseModel from "~/models/BaseModel";
const resource = "users/unread_news_article";

export default class UnreadNewsArticlesRepository extends BaseRepository {
  async show(params = {}) {
    const results = await this._get(resource, params);
    const item = new BaseModel(results.data);
    return item;
  }
}
