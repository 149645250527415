import RegistrationEmailsRepository from "./RegistrationEmailsRepository";
import CalendarsRepository from "./CalendarsRepository";
import TradeHistoryAnalyticsForListsRepository from "./TradeHistoryAnalyticsForListsRepository";
import TradeHistoryAnalyticsForAggregatesRepository from "./TradeHistoryAnalyticsForAggregatesRepository";
import SecuritiesAccountsRepository from "./SecuritiesAccountsRepository";
import TradeHistoryAggregateByCurrenciesRepository from "./TradeHistoryAggregateByCurrenciesRepository";
import TradeHistoryAggregateByTimesRepository from "./TradeHistoryAggregateByTimesRepository";
import TradeHistoryAggregateByWeekdaysRepository from "./TradeHistoryAggregateByWeekdaysRepository";
import TradeHistoryAnnualMonthlyAggregatesRepository from "./TradeHistoryAnnualMonthlyAggregatesRepository";
import NoteListsRepository from "./NoteListsRepository";
import TradeDiaryContentsRepository from "./TradeDiaryContentsRepository";
import TradeDiariesRepository from "./TradeDiariesRepository";
import TradeDiaryFileUploadsRepository from "./TradeDiaryFileUploadsRepository";
import TradeHistoriesRepository from "./TradeHistoriesRepository";
import NewsArticlesRepository from "./NewsArticlesRepository";
import UserReadNewsArticlesRelationshipsRepository from "./UserReadNewsArticlesRelationshipsRepository";
import UnreadNewsArticlesRepository from "./UnreadNewsArticlesRepository";

const repositories = {
  RegistrationEmailsRepository: new RegistrationEmailsRepository,
  CalendarsRepository: new CalendarsRepository,
  TradeHistoryAnalyticsForListsRepository: new TradeHistoryAnalyticsForListsRepository,
  TradeHistoryAnalyticsForAggregatesRepository: new TradeHistoryAnalyticsForAggregatesRepository,
  SecuritiesAccountsRepository: new SecuritiesAccountsRepository,
  TradeHistoryAggregateByCurrenciesRepository: new TradeHistoryAggregateByCurrenciesRepository,
  TradeHistoryAggregateByTimesRepository: new TradeHistoryAggregateByTimesRepository,
  TradeHistoryAggregateByWeekdaysRepository: new TradeHistoryAggregateByWeekdaysRepository,
  TradeHistoryAnnualMonthlyAggregatesRepository: new TradeHistoryAnnualMonthlyAggregatesRepository,
  NoteListsRepository: new NoteListsRepository,
  TradeDiaryContentsRepository: new TradeDiaryContentsRepository,
  TradeDiaryFileUploadsRepository: new TradeDiaryFileUploadsRepository,
  TradeDiariesRepository: new TradeDiariesRepository,
  TradeHistoriesRepository: new TradeHistoriesRepository,
  NewsArticlesRepository: new NewsArticlesRepository,
  UserReadNewsArticlesRelationshipsRepository: new UserReadNewsArticlesRelationshipsRepository,
  UnreadNewsArticlesRepository: new UnreadNewsArticlesRepository
};

export default {
  get: (name) => repositories[name]
};
