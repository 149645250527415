import BaseModel from "~/models/BaseModel";

class CalendarModel extends BaseModel {
  graphLabels() {
    return this.graphDatas ? this.graphDatas.graphLabels : [];
  }

  graphBarData() {
    return this.graphDatas ? this.graphDatas.graphBarData : [];
  }

  graphBarDataForPips() {
    return this.graphDatas ? this.graphDatas.graphBarDataForPips : [];
  }

  graphLineData() {
    return this.graphDatas ? this.graphDatas.graphLineData : [];
  }

  graphLineDataForPips() {
    return this.graphDatas ? this.graphDatas.graphLineDataForPips : [];
  }

  // グラフの表示がtotal_profitかpipsか
  graphTypeTotalProfit() {
    return this.graphDatas.unitType === "total_profit";
  }

  winRateData() {
    return this.aggregate ? this.aggregate.winRateData : [];
  }

  showTotalProfit() {
    return this.aggregate.totalProfit.toLocaleString();
  }

  showProfitAndLoss() {
    return this.aggregate.profitAndLoss.toLocaleString();
  }

  showProfit() {
    return this.aggregate.profit.toLocaleString();
  }

  showCommission() {
    return this.aggregate.commission.toLocaleString();
  }
  showCalendarPips() {
    return this.calendarDisplay === "all" || this.calendarDisplay === "pips_only";
  }
  showCalendarProfit() {
    return this.calendarDisplay === "all" || this.calendarDisplay === "profit_only";
  }
  calendarContentClass() {
    return this.calendarDisplay === "all" ? "" : "one-line-result";
  }
}

export default CalendarModel;
