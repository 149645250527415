<template lang="pug">
  b-modal(
    :active="isShow"
    aria-modal
    custom-class="registration_mail_finish"
    :can-cancel="[]"
  )
    .card
      header.card-header
        p.registration_mail_finish_modal_title
          | 認証メールを送信しました。
      .card-content
        .content
          p.registration_mail_finish_modal_content
            | この度は、無料会員登録ありがとうございます。
            br
            | ご入力いただきましたメールアドレス宛に認証メールをお送りいたしました。
            br
            br
            | 届いたメールから本登録を完了させてください。
            br
            br
            | もしメールが届かない場合は、下記2点をご確認ください。
            br
            br
            | (1)迷惑メールボックスにメールが入っていないかご確認ください。
            br
            | (2)入力されたメールアドレスが間違っていないかご確認し、再度認証メール送信を行ってください。
            br
            br
            | 上記2点をご確認いただき、それでもメールが届かない場合は
            br
            | 恐れ入りますがお問い合わせフォームよりお問い合わせください。
            br
      footer.card-footer.registration_email_finish_modal_footer
        b-button(icon-right="check" @click="close()")
          | 閉じる
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
