import BaseRepository from "./BaseRepository";
import TradeHistoryModel from "~/models/TradeHistoryModel";
const resource = "users/trade_histories/analytics_for_lists";

export default class TradeHistoryAnalyticsForListsRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const items = results.data.map(val => {
      return new TradeHistoryModel(val);
    });
    return items;
  }
}
