import axios from "axios";
import Cookies from "universal-cookie";

const LOGIN_TOKEN_KEY = "userPublicToken";
const cookies = new Cookies();
const token = cookies.get(LOGIN_TOKEN_KEY);
const baseURL = "/api/";
const tokenElement = document.querySelector("meta[name=csrf-token]");
const authorization = "Token YsYwNW0jrURp*Bu95xS^6%Oubl*PTi";

const Repository = axios.create({
  baseURL,
  headers: {
    "X-CSRF-TOKEN": (tokenElement) ? tokenElement.content : null,
    Authorization: authorization,
    Token: token
  }
});
//
export default Repository;
