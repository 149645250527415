<template lang="pug">
  .common-modal(:class="{ 'is-active': isShow && !isClose }")
    .common-info-modal-background
    .common-modal-content
      .modal-card
        .modal-card-header
          a(href="https://www.bigboss-financial.com/ja?aid=PxWa0WSo" target="_blank")
            img(
              src="https://www.bigboss-financial.com/pr/banner/logo_banner_252_88.png"
              alt="bigboss")
          p BBCリリース記念！
        .modal-card-content
          p.campaign-title
            span
            | BigBoss Coin（BBC）リリースキャンペーン開催！
            br
            br
            span.period 期間: 2022年10月17日 ~ 2022年12月31日
          .campaign-content
            p.sub-title ① BBCエアドロップキャンペーン
            p.content
              | 取引量に応じて、BigBoss独自トークン『BBC』が
              span.red 最大1,000BBC
              | までエアドロップ！！
              br
              | BBCは2023年初旬に暗号通貨取引所「CRYPTOS」への上場を予定！
              br
              | 取引で『BBC』を貰えるチャンスは今だけ！！
            p.sub-title ② 入金ボーナスキャンペーン
            p.content
              | 最大
              span.red $6,000
              | 相当のクレジットボーナスが貰える入金ボーナスキャンペーンがスタート！
              br
              | 累積入金額に応じてクレジットボーナスは、最大$6,000相当まで何度でも受け取り可能！
            br
          .link
              a(href="https://www.bigboss-financial.com/ja/cmp/bbc_202210?aid=PxWa0WSo" target="_blank")
                img.pc-image(
                  src="https://www.bigboss-financial.com/pr/banner/bbc_camp_728_90_jp.png"
                  alt="bigboss キャンペーン")
                img.sp-image(
                  src="https://www.bigboss-financial.com/pr/banner/bbc_camp_320_50_jp.png"
                  alt="bigboss キャンペーン")
        .modal-card-footer
          .check-box
            input(type="checkbox" :value="true" v-model="checkBox")
            label 一定期間表示しない
          .button-field
            button.button(type="button" @click="close()") 閉じる
</template>

<script>
import {
  disableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const MODAL_KEY = "notify-modal-01";
const notify = cookies.get(MODAL_KEY);

export default {
  data() {
    return {
      isClose: false,
      checkBox: false
    };
  },
  computed: {
    isShow() {
      // 12月31日まで表示
      return !notify && (this.$dayjs().format("YYYY-MM-DD") <= "2022-12-31");
    }
  },
  mounted() {
    if(this.isShow) {
      const modal = document.querySelector(".common-modal");
      disableBodyScroll(modal);
    }
  },
  methods: {
    close() {
      this.isClose = true;
      this.setNotifyModalCookie();
      clearAllBodyScrollLocks();
    },
    setNotifyModalCookie() {
      const expireDays = this.checkBox ? 5 : 1;
      const expire = this.$dayjs().add(expireDays, "d").format("YYYY-MM-DD 00:00:00");
      cookies.set(MODAL_KEY, true, { expires: new Date(expire) });
    }
  }
};
</script>
