import BaseRepository from "./BaseRepository";
import CalendarModel from "~/models/CalendarModel";
const resource = "users/trade_history_calender";

export default class CalendarsRepository extends BaseRepository {
  async show(params) {
    const results = await this._get(resource, params);
    const item = new CalendarModel(results.data);
    return item;
  }
}
