import BaseRepository from "./BaseRepository";
import NewsArticle from "~/models/NewsArticleModel";
const resource = "users/news_articles";

export default class NewsArticlesRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const items = results.data.items.map(val => {
      return new NewsArticle(val);
    });
    const headers = results.headers;

    return { items, headers };
  }

  async show(id, params = {}) {
    const url = `${resource}/${id}`;
    const results = await this._get(url, params);
    const item = new NewsArticle(results.data);
    return item;
  }
}
