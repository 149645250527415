<template lang="pug">
  div
    form(@submit.prevent="registration()")
      table.registration_top
        tr
          td.registration_input
            input(maxlength="255" placeholder="メールアドレスを入力してください" size="255" type="email" v-model="email")
        tr(v-if="errorText")
          p.error_message
            i.fas.fa-exclamation-triangle
            | {{ errorText }}
        tr(v-if="showDescription")
          p.description
            | 受信可能なメールアドレスをご登録ください。
            br
            | ご入力いただいたメールアドレスに登録用のURLを送信します。
            br
            | 認証メールに記載のあるURLをクリックして、お客さま情報の登録をお願いします。
        th.submit-field
          button.registration_submit(type="submit", :disabled="isDisabled")
            | {{ buttonText }}
    registration-email-finish(:is-show="isFinish" @close="closeFinishModal()")
</template>

<script>
import RegistrationEmailFinish from "~/components/StaticPages/atom/RegistrationEmailFinish";
import RepositoryFactory from "~/repositories/RepositoryFactory";
const RegistrationEmailsRepository = RepositoryFactory.get("RegistrationEmailsRepository");

export default {
  components: {
    RegistrationEmailFinish
  },
  props: {
    buttonText: {
      type: String,
      required: false,
      default: "認証メールを送信する"
    },
    isTop: {
      type: Boolean,
      required: false,
      default: true
    },
    showDescription: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      email: "",
      isDisabled: false,
      errorText: "",
      isFinish: false
    };
  },
  methods: {
    registrationParams() {
      return {
        email: this.email
      };
    },
    async registration() {
      this.isDisabled = true;
      try {
        const registrationEmail = await RegistrationEmailsRepository.create(this.registrationParams());
        if (registrationEmail.result.status === "success") {
          this.email = "";
          this.errorText = "";
          if (this.isTop) this.isFinish = true;
        } else {
          this.errorText = registrationEmail.result.errors;
        }
      } catch {
        this.errorText = "認証メールの送信に失敗しました。再度お試しください。";
      } finally {
        this.isDisabled = false;
      }
    },
    closeFinishModal() {
      this.isFinish = false;
    }
  }
};
</script>
