import Vue from "vue/dist/vue.esm";
import VueCompositionApi from "@vue/composition-api";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import Rollbar from "vue-rollbar";
import Buefy from "buefy";
import "~/../stylesheets/_buefy.scss";
import CKEditor from "@ckeditor/ckeditor5-vue";

// use vue-rollbar
if (process.env.NODE_ENV === "production") {
  Vue.use(Rollbar, {
    accessToken: "4963aa372b9240839cc293400d975ec6",
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    environment: "production",
    payload: {
      client: {
        javascript: {
          code_version: "1.0",
          source_map_enabled: true,
          guess_uncaught_frames: true
        }
      }
    }
  });
}

dayjs.locale("ja");
Vue.prototype.$dayjs = dayjs;
Vue.use(VueCompositionApi);
Vue.use(CKEditor);
Vue.use(Buefy, {
  defaultIconPack: "fas"
});

export { Vue };
