import { Vue } from "~/common/vue";
import RegistrationEmailForm from "~/components/StaticPages/atom/RegistrationEmailForm";
import supportsWebP from "supports-webp";
import "lazysizes";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Top {
  static start() {
    new Vue({
      el: "#content",
      components: {
        RegistrationEmailForm
      },
      data() {
        return {
          isWebp: true,
          isNotWebp: false
        };
      },
      async created() {
        await this.setIsWebp();
      },
      mounted() {
        this.mainHeaderAnimation();
        this.scrollTriggerAnimation();
      },
      methods: {
        async setIsWebp() {
          try {
            if (await supportsWebP) {
              this.isWebp = true;
              this.isNotWebp = false;
            } else {
              this.isWebp = false;
              this.isNotWebp = true;
            }
          } catch {
            // 例外が起こった際はwebp以外を表示するようにしておく
            this.isWebp = false;
            this.isNotWebp = true;
          }
        },
        mainHeaderAnimation() {
          const tl = gsap.timeline({
            repeat: 0,
            defaults: { duration: 1, ease: "power1.in" }
          });
          tl.fromTo("#heading", { y: 5 }, { delay: 0.5, y: 0, opacity: 1 })
            .fromTo("#heading-description", { y:5 }, { y: 0, opacity: 1 }, "text")
            .fromTo("#element1", { y:5 }, { y: 0, opacity: 1 }, "text")
            .fromTo("#heading-registration-btn", { y:5 }, { y: 0, opacity: 1 }, "text+=0.5");
        },
        scrollTriggerAnimation() {
          gsap.fromTo("#element2-title",
            { y: 5 },
            { y: 0, opacity: 1, duration: 1, ease: "power1.in", scrollTrigger: {
              trigger: "#element2-title", start: "center bottom" }
          });
          gsap.fromTo("#element2-first-left",
            { x: -300 },
            { x: 0, opacity: 1, duration: 1, ease: "power1.out", scrollTrigger: {
                trigger: "#element2-first-left", start: "center bottom" }
          });
          gsap.fromTo("#element2-first-right",
            { x: 300 },
            { x: 0, opacity: 1, duration: 1, ease: "power1.out", scrollTrigger: {
                trigger: "#element2-first-left", start: "center bottom" }
          });
          gsap.fromTo("#element2-second-left",
            { x: -300 },
            { x: 0, opacity: 1, duration: 1, ease: "power1.out", scrollTrigger: {
                trigger: "#element2-second-left", start: "center bottom" }
          });
          gsap.fromTo("#element2-second-right",
            { x: 300 },
            { x: 0, opacity: 1, duration: 1, ease: "power1.out", scrollTrigger: {
                trigger: "#element2-second-left", start: "center bottom" }
          });
          gsap.fromTo("#element2-third-left",
            { x: -300 },
            { x: 0, opacity: 1, duration: 1, ease: "power1.out", scrollTrigger: {
                trigger: "#element2-third-left", start: "center bottom" }
          });
          gsap.fromTo("#element2-third-right",
            { x: 300 },
            { x: 0, opacity: 1, duration: 1, ease: "power1.out", scrollTrigger: {
                trigger: "#element2-third-left", start: "center bottom" }
          });
          gsap.fromTo(".element3-item",
            { y: 10 },
            { y: 0, opacity: 1, ease: "power1.in", stagger: 0.3, scrollTrigger: {
                trigger: "#content_element3", start: "center bottom"}
          });
          gsap.fromTo("#sign_up",
            { y: 10 },
            { y: 0, opacity: 1, duration: 1, ease: "power1.in", scrollTrigger: {
                trigger: "#sign_up", start: "center bottom" }
          });
        }
      }
    });
  }
}

export default Top;
