import BaseRepository from "./BaseRepository";
import BaseModel from "~/models/BaseModel";
const resource = "users/note_lists";

export default class NoteListsRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const item = new BaseModel(results.data);
    return item;
  }
}
