import BaseRepository from "./BaseRepository";
import BaseModel from "~/models/BaseModel";
const resource = "users/trade_diary_contents";

export default class TradeDiaryContentsRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const item = new BaseModel(results.data);
    return item;
  }

  async create(params) {
    const results = await this._post(resource, params);
    const item = new BaseModel(results.data);
    return item;
  }
}
