import BaseRepository from "./BaseRepository";
import TradeAggregateDataModel from "~/models/TradeAggregateDataModel";
const resource = "users/trade_histories/analytics_for_list_aggregates";

export default class TradeHistoryAnalyticsForAggregatesRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const items = new TradeAggregateDataModel(results.data);
    return items;
  }
}
