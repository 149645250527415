const router = (controller, action, callback) => {
    // DOMContentLoadedだと実行が遅い
    // bodyタグのデータだと取得まで間に合わない
    // 上記理由からheadのscriptを書いて、window.routingに値を入れる形になった
    const currentController = window.routing.controller;
    const currentAction = window.routing.action;
    if (currentController === controller && currentAction === action) {
        return callback();
    } if (currentController === controller && Array.isArray(action) && action.includes(currentAction)) {
        return callback();
    }
};
export default router;