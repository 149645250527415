import BaseRepository from "./BaseRepository";
import BaseModel from "~/models/BaseModel";
const resource = "users/trade_histories/aggregate_by_currencies";

export default class TradeHistoryAggregateByCurrenciesRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const items = new BaseModel(results.data);
    return items;
  }
}
