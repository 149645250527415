import BaseRepository from "./BaseRepository";
import TradeHistoryAnnualMonthlyAggregatesModel from "~/models/TradeHistoryAnnualMonthlyAggregatesModel";
const resource = "users/trade_histories/annual_monthly_aggregates";

export default class TradeHistoryAnnualMonthlyAggregatesRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const items = new TradeHistoryAnnualMonthlyAggregatesModel(results.data);
    return items;
  }
}
