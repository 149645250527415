import BaseModel from "~/models/BaseModel";
import "dayjs/locale/ja";
import dayjs from "dayjs";

class TradeAggregateDataModel extends BaseModel {
  showLabels() {
    return this.graphData.labels.map(item => dayjs(item).format("YYYY/MM/DD HH:mm:ss"));
  }

  winRateData() {
    const winningPercentage = Math.round(this.winningPercentage);
    return winningPercentage !== 0 ? [winningPercentage, 100 - winningPercentage] : [0, 1000];
  }
}

export default TradeAggregateDataModel;
