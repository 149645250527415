// camelcaseKeys,snakeCaseKeysを使いたかったがエラーにで使えなかったので代用
import camelCase from "lodash.camelcase";
import snakeCase from "lodash.snakecase";
import deepMapKeys from "~/libs/deepMapKeys";

class BaseModel {
  constructor(init) {
    Object.assign(this, deepMapKeys(init, (val, key) => {
      return camelCase(key);
    }));
  }

  toApiParams() {
    return deepMapKeys(this, (val, key) => {
      return snakeCase(key);
    });
  }
}

export default BaseModel;
