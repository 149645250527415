import BaseRepository from "./BaseRepository";
import SecuritiesAccountModel from "~/models/SecuritiesAccountModel";
const resource = "users/securities_accounts";

export default class SecuritiesAccountsRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const items = results.data.map(val => {
      return new SecuritiesAccountModel(val);
    });
    return items;
  }

  async show(id, params = {}) {
    const url = `${resource}/${id}`;
    const results = await this._get(url, params);
    const item = new SecuritiesAccountModel(results.data);
    return item;
  }

  async create(params) {
    const results = await this._post(`${resource}`, params);
    const item = new SecuritiesAccountModel(results.data);
    return item;
  }

  async update(id, params = {}) {
    const url = `${resource}/${id}`;
    const results = await this._put(url, params);
    const item = new SecuritiesAccountModel(results.data);
    return item;
  }

  async destroy(id) {
    const url = `${resource}/${id}`;
    await this._delete(url);
    return null;
  }
}
