import { Vue } from "~/common/vue";
import RegistrationEmailForm from "~/components/StaticPages/atom/RegistrationEmailForm";

class New {
  static start() {
    new Vue({
      el: "#content",
      components: {
        RegistrationEmailForm
      },
      data() {
        return {};
      }
    });
  }
}

export default New;
