import BaseRepository from "./BaseRepository";
import BaseModel from "~/models/BaseModel";
const resource = "registration_emails";

export default class RegistrationEmailsRepository extends BaseRepository {
  async create(params) {
    const results = await this._post(`${resource}`, params);
    const RegistrationEmail = new BaseModel(results.data);
    return RegistrationEmail;
  }
}
