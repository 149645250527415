import BaseRepository from "./BaseRepository";
import TradeHistoryModel from "~/models/TradeHistoryModel";
const resource = "users/trade_histories";

export default class TradeHistoriesRepository extends BaseRepository {
  async show(id, params = {}) {
    const url = `${resource}/${id}`;
    const results = await this._get(url, params);
    const item = new TradeHistoryModel(results.data);
    return item;
  }

  async update(id, params = {}) {
    const url = `${resource}/${id}`;
    const results = await this._put(url, params);
    const item = new TradeHistoryModel(results.data);
    return item;
  }
}
