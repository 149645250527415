import BaseRepository from "./BaseRepository";
import TradeDiaryModel from "~/models/TradeDiaryModel";
const resource = "users/trade_diaries";

export default class TradeDiariesRepository extends BaseRepository {
  async index(params) {
    const results = await this._get(resource, params);
    const item = new TradeDiaryModel(results.data);
    return item;
  }
}
