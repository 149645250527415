import BaseRepository from "./BaseRepository";
import BaseModel from "~/models/BaseModel";
const resource = "users/user_read_news_articles_relationships";

export default class UserReadNewsArticlesRelationshipsRepository extends BaseRepository {
  async create(params) {
    const results = await this._post(`${resource}`, params);
    const item = new BaseModel(results.data);
    return item;
  }
}
