import BaseModel from "~/models/BaseModel";

class TradeHistoryModel extends BaseModel {
  // 編集可能かどうか
  enableEdit() {
    // CSVアップロードしたデータのみ編集を許可する
    return this.securitiesAccount && this.securitiesAccount.platform === "CSVアップロード";
  }

  showTicket() {
    return this.ticket.length > 11 ? this.ticket.substring(0, 8) + "..." : this.ticket;
  }
}

export default TradeHistoryModel;
