import BaseRepository from "./BaseRepository";
import BaseModel from "~/models/BaseModel";
const resource = "users/trade_diary_file_upload";

export default class TradeDiaryFileUploadsRepository extends BaseRepository {
  async create(params) {
    const results = await this._postWithFormData(resource, params);
    const item = new BaseModel(results.data);
    return item;
  }
}
